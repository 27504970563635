@import 'src/style/mixins';

.Container {
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-size: 257% 67%;
  background-repeat: no-repeat;
  position: relative;
  background-position: bottom;
  background-image: url('/images/backgrounds/404-bg.svg');

  @include tablet {
    background-size: 180% 58%;
  }

  @include desktop {
    background-size: 102% 60%;
  }
}

.Logo{
  position: absolute;
  width: 29px;
  height: 26.04px;
  top: 17.8px;
  left: 20px;
  @include tablet{
      display: none;
  }
}

.LogoDesktop{
  display: none;
  @include tablet{
    display: inline;
    position: absolute;
    width: 245px;
    height: 60px;
    top: 19px;
    left: 27px;
  }

  @include desktop{
    top: 28px;
    left: 43px;
  }
}

.ErrorText{
  font-size: 15px;
  color: $color-black3;
  margin-bottom: 17.5px;
  font-weight: $fontWeights-bold;
  @include tablet{
    margin-bottom: 29.5px;
  }

  @include tablet{
    margin-bottom: 41.5px;
  }
}

.Image404{
  width: 187px;
  height: 124px;
  margin-bottom: 8px;
  @include tablet{
    width: 241px;
    height: 160px;
    margin-bottom: 18px;
}

  @include desktop {
    width: 344px;
    height: 227px;
    margin-bottom: 27px;
  }
}



.MainText{
  font-size: 21px;
  padding: 0;
  margin: 0;
  font-weight: $fontWeights-bold;
  @include tablet {
    font-size: 28px;
    margin-bottom: 8px;
    >br{
      display: none;
    }
  }

  @include desktop {
    font-size: 39px;
    margin-bottom: 12px;

  }

}

.Paragraph{
  font-size: 14px;
  font-weight: $fontWeights-light;
  width: 286px;
  margin-bottom: 39px;
  margin-top: 6px;
  @include tablet {
    font-size: 16px;
    width: 420px;
    margin-top: 0;
    margin-bottom: 23px;

  }
  @include desktop{
    width: 100%;
    margin-bottom: 47px;
    font-size: 22px;
  }
}

.Button{
  width: 176px;
  height: 45px;
  background-color: $color-blue2;
  color: white;
  font-size: 12px;
  font-weight: $fontWeights-semiBold;
  border-radius: 4px;
  border: none;
  margin-bottom: 46px;
  cursor: pointer;

  @include desktop {
    width: 244px;
    height: 53px;
    font-size: 18px;
    margin-bottom: 66px;
  }

  &:hover{
    opacity: 0.8;
  }
}

.Contact{
  font-size: 12px;
  text-decoration: underline ;
  color: inherit;
  font-weight: $fontWeights-light;
  @include desktop {
    font-size: 17px;
  }
}